/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEtaTranslations } from "shared/hooks/useEtaTranslations";

import Colors from "styles/colors";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import { DateTimeRange } from "components/atoms/DateTimeRange.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { translateRailStatusCode } from "utils/translation-utils";
import { MadChicletCSS as MadChiclet } from "components/chiclets";
import {
  localizedTimeFormatter,
  localizedDateFormatter,
} from "utils/date-time";
import { parse } from "utils/json-utils";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { getCurrentPositionDetails } from "shared/utils/entity.utils";
import { VinMilestoneCell } from "components/organisms/base-table/Cell/VinMilestoneCell";
import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";
import FinVehicleEntityDetailsState from "pages/finishedvehicle/redux/FinVehicleEntityDetailsState";
import { SourceType } from "api/consts";
import { translateExceptionName } from "pages/finishedvehicle/utils/exceptions.utils";
import { ShippabilityStatusText } from "shared/components/molecules/ShippabilityStatusText.molecule";
import { FeaturedReference } from "shared/components/molecules/FeaturedReference.molecule";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";
import {
  faCopy,
  faCheckSquare as faCheck,
} from "@fortawesome/pro-light-svg-icons";

// Helpers
const findReference = (references, key) => {
  if (references === undefined) {
    return null;
  }
  const ref = references.find((obj) => obj.qualifier === key);
  return ref ? ref.value : null;
};
// Constants
const ITSS_EXCEPTION = "In Transit Stop Ship";
const SPOT_BUY_EXCEPTION = "Spot Buy";

const VinCell = (props) => {
  let {
    id,
    description,
    references,
    orderTypeNew,
    orderNumberNew,
    commentsCount,
    lifeCycleState,
    isShippable,
    isOpenSearchFeatureEnabled,
    displayShippabilityStatus,
    featuredReferences,
    shippableAtTs,
  } = props.value;
  const { t } = useTranslation("fv-vin-search");
  const orderType = isOpenSearchFeatureEnabled
    ? orderTypeNew
    : findReference(references, "OrderType") || "";
  const orderNumber = isOpenSearchFeatureEnabled
    ? orderNumberNew
    : findReference(references, "OrderNumber") || "";

  const [clipboardIcon, setClipboardIcon] = useState(faCopy);
  const [clipboardIconColor, setClipboardIconColor] = useState(
    Colors.highlight.DARK_GRAY,
  );

  const handleCopy = (e) => {
    navigator.clipboard.writeText(id);
    setClipboardIcon(faCheck);
    setClipboardIconColor(Colors.highlight.GREEN);
    setTimeout(() => {
      setClipboardIcon(faCopy);
      setClipboardIconColor(Colors.highlight.DARK_GRAY);
    }, 1800);
    e.stopPropagation();
  };

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div
        css={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {commentsCount && commentsCount > 0 ? (
          <Tooltip
            placement="top"
            tooltipChildren={
              <Text>{t("fv-vin-search:This VIN contains comments")}</Text>
            }
          >
            <Icon
              src={faCommentAlt}
              color={Colors.comments.unreadCommentIcon}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
        ) : null}
        <div css={{ display: "flex", alignItems: "center" }}>
          <Text bold css={{ marginRight: "0.5em" }}>
            {lifeCycleState === "Prebuilt" ? "TBD" : id}
          </Text>
          <Icon
            src={clipboardIcon}
            size={FontSize.size16}
            color={clipboardIconColor}
            onClick={handleCopy}
            css={{ cursor: "pointer" }}
          />
        </div>
      </div>
      {description && description !== "None" ? (
        <Text>{description}</Text>
      ) : null}
      <div css={{ display: "flex", flexDirection: "row" }}>
        {orderType ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              {t("fv-vin-search:Order Type")} -
            </Text>
            <Text>{orderType}</Text>
          </React.Fragment>
        ) : null}
      </div>
      <div css={{ display: "flex", flexDirection: "row" }}>
        {orderNumber ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              {t("fv-vin-search:Order #")} -
            </Text>
            <Text>{orderNumber}</Text>
          </React.Fragment>
        ) : null}
      </div>

      {featuredReferences && featuredReferences.length > 0 && (
        <FeaturedReference featuredReferences={featuredReferences} />
      )}

      {displayShippabilityStatus && (
        <ShippabilityStatusText
          isShippable={isOpenSearchFeatureEnabled ? shippableAtTs : isShippable}
        ></ShippabilityStatusText>
      )}
    </div>
  );
};

VinCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    orderType: PropTypes.string,
    orderNumber: PropTypes.string,
    commentsCount: PropTypes.number,
    lifeCycleState: PropTypes.string,
    isShippable: PropTypes.bool,
    displayShippabilityStatus: PropTypes.bool,
    featuredReferences: PropTypes.array,
    isOpenSearchFeatureEnabled: PropTypes.bool,
    references: PropTypes.array,
    orderNumberNew: PropTypes.string,
    orderTypeNew: PropTypes.string,
    shippableAtTs: PropTypes.string,
  }),
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const LastUpdateCell = (props) => {
  const { mode, lastPosition, lastProgress, currentLocation, routeId } =
    props.value;

  const { t } = useTranslation("fv-vin-search");

  let isOnShipment = false;

  if (lastPosition?.currentPositionType !== t("fv-vin-search:At Location")) {
    isOnShipment = true;
  }

  let city = null;
  let state = null;

  if (lastPosition) {
    city = lastPosition?.city?.trim();
    state = lastPosition?.state?.trim();
  }

  let cityAndState = null;
  if (city || state) {
    if (city && !state) {
      cityAndState = city;
    } else if (state && !city) {
      cityAndState = state;
    } else {
      cityAndState = `${city}, ${state}`;
    }
  }

  let modeType = mode;
  if (lastPosition?.currentPositionType === t("fv-vin-search:On Rail")) {
    modeType = "Rail";
  } else if (
    lastPosition?.currentPositionType === t("fv-vin-search:On the Road")
  ) {
    modeType = "Truck";
  } else if (
    lastPosition?.currentPositionType === t("fv-vin-search:On the Water")
  ) {
    modeType = "Ocean";
  }

  let source = lastPosition?.sourceType;
  if (source === SourceType.SHIPMENT) {
    source = t("fv-vin-search:Shipment");
  } else if (source === SourceType.CONNECTED_CAR || source === SourceType.TAG) {
    source = t("fv-vin-search:Connected Car");
  } else if (source === SourceType.MILESTONE) {
    source = t("fv-vin-search:Milestone");
  }

  const routeIdElement = (
    <div>
      <Text bold>{t("fv-vin-search:Route ID")} - </Text>
      <Text>{routeId ?? t("fv-vin-search:N/A")}</Text>
    </div>
  );

  if (
    isOnShipment &&
    (lastProgress?.eventTs || cityAndState || lastPosition?.sourceType)
  ) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <div className="d-flex align-items-center flex-wrap">
          {modeType ? (
            <MadChiclet
              style={{ marginRight: "0.5em" }}
              shipmentMode={modeType}
              stopMode={modeType}
              activeException={null}
              height={24}
              width={24}
            />
          ) : null}
          <Text>
            {lastPosition?.currentPositionType ??
              t("fv-vin-search:On Shipment")}
          </Text>
        </div>

        {/* Departing Location */}
        {source === t("fv-vin-search:Shipment") ||
        source === t("fv-vin-search:Milestone") ? (
          <React.Fragment>
            {lastPosition?.currentPositionName ? (
              <Text className="ms-1">{lastPosition?.currentPositionName}</Text>
            ) : null}
          </React.Fragment>
        ) : null}

        {/* City/State */}
        {cityAndState ? <Text className="ms-1">{cityAndState}</Text> : null}

        {/* Last CLM Status (Rail) */}
        {mode === "Rail" &&
          modeType === "Rail" &&
          lastProgress?.position?.eventCode && (
            <div className="d-flex flex-wrap">
              <Text>
                {translateRailStatusCode(lastProgress.position.eventCode, t)}
              </Text>
            </div>
          )}

        {/* Source */}
        {source ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("fv-vin-search:Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}

        {/*Departed Time */}
        {lastPosition?.datetime ? (
          <div className="d-flex flex-wrap">
            <Text className="ms-1">
              {`${localizedTimeFormatter(
                lastPosition?.datetime,
              )} ${localizedDateFormatter(lastPosition?.datetime)}`}
            </Text>
          </div>
        ) : null}
        {routeIdElement}
      </div>
    );
  } else if (
    !isOnShipment &&
    (lastPosition?.datetime || currentLocation?.name || cityAndState)
  ) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <Text>{t("fv-vin-search:At Location")}</Text>
        {/* Location Name */}
        {lastPosition?.currentPositionName ? (
          <Text className="ms-1">{lastPosition?.currentPositionName}</Text>
        ) : null}
        {/* City/State */}
        {cityAndState ? <Text className="ms-1">{cityAndState}</Text> : null}
        {/* Source */}
        {source ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("fv-vin-search:Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}
        {/* Arrival Time */}
        {lastPosition?.datetime ? (
          <div className="d-flex flex-wrap">
            {/* <Text>Departed At:</Text> */}
            <Text className="ms-1">
              {`${localizedTimeFormatter(
                lastPosition?.datetime,
              )} ${localizedDateFormatter(lastPosition?.datetime)}`}
            </Text>
          </div>
        ) : null}
        {routeIdElement}
      </div>
    );
  }

  return null;
};

LastUpdateCell.propTypes = {
  value: PropTypes.shape({
    lastPosition: PropTypes.object,
    lastProgress: PropTypes.string,
    state: PropTypes.string,
    mode: PropTypes.string,
    currentLocation: PropTypes.object,
    routeId: PropTypes.string,
  }).isRequired,
};

const LastUpdateCellOpenSearch = (props) => {
  const { mode, lastPosition, lastProgress, currentLocation } = props.value;

  const { t } = useTranslation("fv-vin-search");

  let isOnShipment = false;

  if (lastPosition?.currentPositionType !== t("fv-vin-search:At Location")) {
    isOnShipment = true;
  }

  let city = null;
  let state = null;

  if (lastPosition) {
    city = lastPosition?.city?.trim();
    state = lastPosition?.state?.trim();
  }

  let cityAndState = null;
  if (city || state) {
    if (city && !state) {
      cityAndState = city;
    } else if (state && !city) {
      cityAndState = state;
    } else {
      cityAndState = `${city}, ${state}`;
    }
  }

  let modeType = mode;
  if (lastPosition?.currentPositionType === t("fv-vin-search:On Rail")) {
    modeType = "Rail";
  } else if (
    lastPosition?.currentPositionType === t("fv-vin-search:On the Road")
  ) {
    modeType = "Truck";
  } else if (
    lastPosition?.currentPositionType === t("fv-vin-search:On the Water")
  ) {
    modeType = "Ocean";
  }

  let source = lastPosition?.sourceType;
  if (source === SourceType.SHIPMENT) {
    source = t("fv-vin-search:Shipment");
  } else if (source === SourceType.CONNECTED_CAR || source === SourceType.TAG) {
    source = t("fv-vin-search:Connected Car");
  } else if (source === SourceType.MILESTONE) {
    source = t("fv-vin-search:Milestone");
  }

  if (isOnShipment && (lastProgress || cityAndState || source)) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <div className="d-flex align-items-center flex-wrap">
          {modeType ? (
            <MadChiclet
              style={{ marginRight: "0.5em" }}
              shipmentMode={modeType}
              stopMode={modeType}
              activeException={null}
              height={24}
              width={24}
            />
          ) : null}
          <Text>
            {lastPosition?.currentPositionType ??
              t("fv-vin-search:On Shipment")}
          </Text>
        </div>

        {/* Departing Location */}
        {source === t("fv-vin-search:Shipment") ||
        source === t("fv-vin-search:Milestone") ? (
          <React.Fragment>
            {lastPosition?.currentPositionName ? (
              <Text className="ms-1">{lastPosition?.currentPositionName}</Text>
            ) : null}
          </React.Fragment>
        ) : null}

        {/* City/State */}
        {cityAndState ? <Text className="ms-1">{cityAndState}</Text> : null}

        {/* Last CLM Status (Rail) */}
        {mode === "Rail" && modeType === "Rail" && lastProgress && (
          <div className="d-flex flex-wrap">
            <Text>{translateRailStatusCode(lastProgress, t)}</Text>
          </div>
        )}

        {/* Source */}
        {source ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("fv-vin-search:Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}

        {/*Departed Time */}
        {lastPosition?.eventTs ? (
          <div className="d-flex flex-wrap">
            <Text className="ms-1">
              {`${localizedTimeFormatter(
                lastPosition?.eventTs,
              )} ${localizedDateFormatter(lastPosition?.eventTs)}`}
            </Text>
          </div>
        ) : null}
      </div>
    );
  } else if (
    !isOnShipment &&
    (lastPosition?.eventTs || currentLocation?.name || cityAndState)
  ) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <Text>{t("fv-vin-search:At Location")}</Text>
        {/* Location Name */}
        {lastPosition?.currentPositionName ? (
          <Text className="ms-1">{lastPosition?.currentPositionName}</Text>
        ) : null}
        {/* City/State */}
        {cityAndState ? <Text className="ms-1">{cityAndState}</Text> : null}
        {/* Source */}
        {source ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("fv-vin-search:Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}
        {/* Arrival Time */}
        {lastPosition?.eventTs ? (
          <div className="d-flex flex-wrap">
            {/* <Text>Departed At:</Text> */}
            <Text className="ms-1">
              {`${localizedTimeFormatter(
                lastPosition?.eventTs,
              )} ${localizedDateFormatter(lastPosition?.eventTs)}`}
            </Text>
          </div>
        ) : null}
      </div>
    );
  }

  return null;
};

LastUpdateCellOpenSearch.propTypes = {
  value: PropTypes.shape({
    lastPosition: PropTypes.object,
    lastProgress: PropTypes.string,
    state: PropTypes.string,
    mode: PropTypes.string,
    currentLocation: PropTypes.object,
    routeId: PropTypes.string,
    isOpenSearchFeatureEnabled: PropTypes.bool,
  }).isRequired,
};

const ExceptionCell = (props) => {
  const { exceptions, categories, showItssData, showSpotBuyData } = props.value;
  const { t } = useTranslation(["fv-vin-search", "exceptions"]);

  const usualExceptions = exceptions?.filter(
    (exception) =>
      exception.reasonCode !== "ITSS" && exception.reasonCode !== "SB",
  );

  let itssIds = [];
  let spotBuyCodes = [];
  if (categories?.length > 0) {
    itssIds = categories
      .filter((category) => category.group === "In Transit Stop Ship")
      .map((item) => item.category);
    spotBuyCodes = categories
      .filter((category) => category.group === "Spot Buy")
      .map((item) => item.category);
  }

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {_.uniqBy(usualExceptions, "name")?.map((exception, index) => (
        <Text bold key={index}>
          {translateExceptionName(exception.name, t)}
        </Text>
      ))}
      {/* ITSS IDs */}
      {showItssData && itssIds.length ? (
        <React.Fragment>
          <Text bold>{t("fv-vin-search:In Transit Stop Ship")}</Text>
          <div className="text-nowrap ms-2">
            <ShowMoreList
              title={t("fv-vin-search:In Transit Stop Ship IDs")}
              list={itssIds}
              visibleItemCount={4}
            />
          </div>
        </React.Fragment>
      ) : null}
      {/* Spot Buy Auth Codes */}
      {showSpotBuyData && spotBuyCodes.length ? (
        <React.Fragment>
          <Text bold>{t("fv-vin-search:Spot Buy Authorization Number")}</Text>
          <div className="text-nowrap ms-2">
            <ShowMoreList
              title={t("fv-vin-search:Spot Buy Authorization Number")}
              list={spotBuyCodes}
              visibleItemCount={4}
            />
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

ExceptionCell.propTypes = {
  value: PropTypes.shape({
    exceptions: PropTypes.array,
    categories: PropTypes.array,
    showItssData: PropTypes.bool,
    showSpotBuyData: PropTypes.bool,
  }),
};

const ExceptionCellOpenSearch = (props) => {
  const {
    exceptions,
    categories,
    showItssData,
    showSpotBuyData,
    isOpenSearchFeatureEnabled,
  } = props.value;
  const { t } = useTranslation(["fv-vin-search", "exceptions"]);

  const usualExceptions = exceptions?.filter(
    (exception) =>
      exception.reasonCode !== "ITSS" && exception.reasonCode !== "SB",
  );

  let itssIds = [];
  let spotBuyCodes = [];
  if (isOpenSearchFeatureEnabled) {
    const exceptionCategories = exceptions?.filter(
      (category) =>
        category.groupTypeDescription === ITSS_EXCEPTION ||
        category.groupTypeDescription === SPOT_BUY_EXCEPTION,
    );

    if (exceptionCategories?.length > 0) {
      itssIds = exceptionCategories
        .filter((category) => category.groupTypeDescription === ITSS_EXCEPTION)
        .map((item) => item.groupCategory);
      spotBuyCodes = exceptionCategories
        .filter(
          (category) => category.groupTypeDescription === SPOT_BUY_EXCEPTION,
        )
        .map((item) => item.groupCategory);
    }
  } else {
    if (categories?.length > 0) {
      itssIds = categories
        .filter((category) => category.group === ITSS_EXCEPTION)
        .map((item) => item.category);
      spotBuyCodes = categories
        .filter((category) => category.group === SPOT_BUY_EXCEPTION)
        .map((item) => item.category);
    }
  }

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {_.uniqBy(usualExceptions, "name")?.map((exception, index) => (
        <Text bold key={index}>
          {translateExceptionName(exception.name, t)}
        </Text>
      ))}
      {/* ITSS IDs */}
      {showItssData && itssIds.length ? (
        <React.Fragment>
          <Text bold>{t("fv-vin-search:In Transit Stop Ship")}</Text>
          <div className="text-nowrap ms-2">
            <ShowMoreList
              title={t("fv-vin-search:In Transit Stop Ship IDs")}
              list={itssIds}
              visibleItemCount={4}
            />
          </div>
        </React.Fragment>
      ) : null}
      {/* Spot Buy Auth Codes */}
      {showSpotBuyData && spotBuyCodes.length ? (
        <React.Fragment>
          <Text bold>{t("fv-vin-search:Spot Buy Authorization Number")}</Text>
          <div className="text-nowrap ms-2">
            <ShowMoreList
              title={t("fv-vin-search:Spot Buy Authorization Number")}
              list={spotBuyCodes}
              visibleItemCount={4}
            />
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

ExceptionCellOpenSearch.propTypes = {
  value: PropTypes.shape({
    exceptions: PropTypes.array,
    categories: PropTypes.array,
    showItssData: PropTypes.bool,
    showSpotBuyData: PropTypes.bool,
    isOpenSearchFeatureEnabled: PropTypes.bool,
  }),
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */

const LocationCell = (props) => {
  const {
    location,
    type,
    eta,
    tripPlanCompleteTs,
    manualEtaRangeStart,
    manualEtaRangeEnd,
    showScheduleWindow,
    completedDate = null,
    isOpenSearchFeatureEnabled,
  } = props.value;
  const { t } = useTranslation("fv-vin-search");
  const { getEtaTranslation, isEtaName } = useEtaTranslations();
  if (_.isNil(location)) {
    return <div />;
  }

  const scheduledWindow = isOpenSearchFeatureEnabled
    ? location?.scheduledArrivalWindow
    : parse(location.scheduledArrivalWindow);
  const isPickUpOr = type === "origin" ? t("Pickup") : t("Delivery");

  const arrivedTime = isOpenSearchFeatureEnabled
    ? location.arrivedTs
    : location.arrived;

  // eslint-disable-next-line no-undef
  const actualTsBool = Boolean(arrivedTime) || Boolean(tripPlanCompleteTs);

  const timeToDisplay = arrivedTime
    ? arrivedTime
    : tripPlanCompleteTs
    ? tripPlanCompleteTs
    : showScheduleWindow
    ? scheduledWindow
    : null;

  const timeToDisplayLabel = actualTsBool ? t("Actual") : t("Scheduled");

  let etaElement = <DateTime dateTime={eta} plain localize />;
  if (isEtaName(eta)) {
    etaElement = (
      <Text>
        {getEtaTranslation(eta, manualEtaRangeStart, manualEtaRangeEnd)}
      </Text>
    );
  }

  const etaContent = isOpenSearchFeatureEnabled ? eta : etaElement;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text bold>{location.code}</Text>
      <Text>{location.name}</Text>
      {location.city && location.state ? (
        <Text>{`${location.city}, ${location.state}`}</Text>
      ) : null}
      {timeToDisplay ? (
        <React.Fragment>
          <Text bold underline>
            {timeToDisplayLabel} {isPickUpOr}
          </Text>
          {!actualTsBool ? (
            <DateTimeRange
              plain
              localize
              from={timeToDisplay[0]}
              to={timeToDisplay[1]}
            />
          ) : (
            <DateTime plain localize dateTime={timeToDisplay} />
          )}
        </React.Fragment>
      ) : null}
      {eta && (
        <div css={{ display: "flex", flexDirection: "row" }}>
          <Text bold>{t("fv-vin-search:ETA")}:</Text>
          <Text css={{ marginLeft: 3 }}>{etaContent}</Text>
        </div>
      )}
      {completedDate ? (
        <React.Fragment>
          <Text bold underline>
            {t("Complete Date")}
          </Text>
          <DateTime plain localize dateTime={completedDate} />
        </React.Fragment>
      ) : null}
    </div>
  );
};

LocationCell.propTypes = {
  value: PropTypes.object.isRequired,
};

export const columns = (
  t,
  solutionId,
  showItssData,
  showSpotBuyData,
  showScheduleWindow,
  isOpenSearchFeatureEnabled,
  displayShippabilityStatus,
) => {
  const results = [
    {
      Header: t("fv-vin-search:Watch"),
      accessor: "watch",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const dispatch = useDispatch();
        const vin = isOpenSearchFeatureEnabled
          ? cellInfo.row.original.externalId
          : cellInfo.row.original.id;
        // API returns boolean in a string
        const watched = isOpenSearchFeatureEnabled
          ? Boolean(cellInfo.value)
          : cellInfo.value === "true";
        return (
          <WatchToggle
            key={vin}
            checked={watched ?? false}
            onChange={(newWatchValue) => {
              isOpenSearchFeatureEnabled
                ? dispatch(
                    FinVehicleEntityDetailsState.actionCreators.setNewWatchEntity(
                      solutionId,
                      vin,
                      newWatchValue,
                    ),
                  )
                : dispatch(
                    FinVehicleEntityDetailsState.actionCreators.setWatchEntity(
                      solutionId,
                      vin,
                      newWatchValue,
                    ),
                  );
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("fv-vin-search:VIN"),
      id: isOpenSearchFeatureEnabled ? "externalId" : "vin",
      Cell: VinCell,
      accessor: (d) => {
        let returnObj = {};
        if (isOpenSearchFeatureEnabled) {
          returnObj = {
            id: d?.externalId,
            description: d?.description,
            references: d?.references,
            orderTypeNew: d?.order?.type,
            orderNumberNew: d?.order?.number,
            commentsCount: d?.commentsCount,
            lifeCycleState: d?.lifeCycleState,
            isShippable: d?.isShippable,
            isOpenSearchFeatureEnabled,
            displayShippabilityStatus,
            featuredReferences: d?.featuredReferences,
            shippableAtTs: d?.shippableAtTs,
          };
        } else {
          returnObj = {
            id: d.id,
            description: d.description,
            references: d.references,
            commentsCount: d.commentsCount,
            lifeCycleState: d.lifeCycleState,
            isShippable: d.isShippable,
            displayShippabilityStatus:
              d.configurations?.displayShippabilityStatus,
            featuredReferences: d.featuredReferences,
          };
        }
        return returnObj;
      },
      minWidth: 180,
    },
    {
      Header: t("fv-vin-search:Last Milestone"),
      id: "lastMilestone",
      minWidth: 125,
      Cell: (cellInfo) => {
        return (
          <VinMilestoneCell
            lastMilestone={cellInfo?.row?.original?.lastStatusUpdate}
            isOpenSearchFeatureEnabled={isOpenSearchFeatureEnabled}
          />
        );
      },
    },
    {
      Header: t("fv-vin-search:Last Update"),
      id: isOpenSearchFeatureEnabled
        ? "actualTripLegs.externalId"
        : "activeShipment",
      minWidth: 125,
      Cell: isOpenSearchFeatureEnabled
        ? LastUpdateCellOpenSearch
        : LastUpdateCell,
      accessor: (d) => {
        let returnObj = {};
        if (isOpenSearchFeatureEnabled) {
          returnObj = {
            mode: d?.lastPositionUpdate?.positionType,
            lastPosition: getCurrentPositionDetails(d, t),
            lastProgress: d?.lastPositionUpdate?.locationCode,
            currentLocation: d?.locationData?.current,
            isOpenSearchFeatureEnabled,
          };
        } else {
          returnObj = {
            mode: d.activeTransportMode,
            lastPosition: getCurrentPositionDetails(d, t),
            lastProgress: d.lastProgressUpdate,
            currentLocation: d.locationData?.current,
            routeId: d.routeNumber,
          };
        }
        return returnObj;
      },
    },
    {
      Header: t("fv-vin-search:Origin Details"),
      id: isOpenSearchFeatureEnabled ? "ultimateOrigin.name" : "origin",
      minWidth: 135,
      Cell: LocationCell,
      accessor: (d) => {
        let returnObj = {};
        if (isOpenSearchFeatureEnabled) {
          returnObj = {
            location: d?.ultimateOrigin,
            type: "origin",
            showScheduleWindow,
            isOpenSearchFeatureEnabled,
          };
        } else {
          returnObj = {
            location: d?.ultimateOrigin,
            type: "origin",
            showScheduleWindow: showScheduleWindow,
          };
        }
        return returnObj;
      },
    },
    {
      Header: t("fv-vin-search:Destination Details"),
      id: isOpenSearchFeatureEnabled
        ? "ultimateDestination.name"
        : "destination",
      minWidth: 135,
      Cell: LocationCell,
      accessor: (d) => {
        let returnObj = {};
        if (isOpenSearchFeatureEnabled) {
          returnObj = {
            location: d?.ultimateDestination,
            type: "destination",
            eta: d?.eta?.displayValue,
            t,
            showScheduleWindow,
            completedDate: d?.completedAtTs,
          };
        } else {
          let eta = null;
          // If the VIN is not delivered, show the ETA.
          if (d?.lifeCycleState !== "Delivered") {
            // `lastEntityProgressUpdate.destinationEta` is the source of the ETA for VINView if an etaDateRange is not avaliable.
            // The API will handle which ETA to show (FV, HERE, Smart, etc) in this field.
            eta = d?.lastEntityProgressUpdate?.destinationEta;
          }

          returnObj = {
            location: d.ultimateDestination,
            type: "destination",
            eta: eta,
            t: t,
            tripPlanCompleteTs: d?.tripPlanCompleteTs,
            manualEtaRangeStart: d?.manualEtaRangeStart,
            manualEtaRangeEnd: d?.manualEtaRangeEnd,
            showScheduleWindow: showScheduleWindow,
            completedDate: d?.completedDate,
          };
        }
        return returnObj;
      },
    },
    {
      Header: t("fv-vin-search:Active Exceptions"),
      id: "shipmentExeptions",
      disableSortBy: true,
      minWidth: 125,
      Cell: isOpenSearchFeatureEnabled
        ? ExceptionCellOpenSearch
        : ExceptionCell,
      accessor: (d) => {
        let returnObj = {
          references: d?.references,
          exceptions: d?.exceptions,
          categories: d?.categories,
          showItssData,
          showSpotBuyData,
          t,
        };
        if (isOpenSearchFeatureEnabled) {
          returnObj = {
            ...returnObj,
            isOpenSearchFeatureEnabled,
          };
        }
        return returnObj;
      },
    },
  ];

  return results;
};
