import _ from "lodash";
import PropTypes from "prop-types";
import {
  productTypeOptionsStateOpenSearch,
  lastMilestoneOptionsStateOpenSearch,
  soldToOptionsStateOpenSearch,
  partnerOptionsStateOpenSearch,
  originCountryOptionsStateOpenSearch,
  destinationCountryOptionsStateOpenSearch,
  dealerRegionOptionsStateOpenSearch,
  dealerDistrictOptionsStateOpenSearch,
  dealerZoneOptionsStateOpenSearch,
  endUserFinCodeOptionsStateOpenSearch,
  exteriorColorOptionsState,
  interiorColorOptionsState,
  doorsOptionsState,
  powertrainOptionsState,
  originOptionsState,
  destinationOptionsState,
  currentPositionsCodeOptionsState,
  finCodeOptionsState,
  routeIdOptionsStateOpenSearch,
  // TODO: Shiva: Add these new fitlers to the OPEN_SEARCH_FILTERS array once confirmed
  // drivetrainOptionsState,
  // bodyStyleOptionsState,
  // fuelTypeOptionsState,
  // yearOptionsState,
  // makeOptionsState,
  // modelOptionsState,
  // trimOptionsState,
} from "pages/finishedvehicle/search/FinVehicleSearchFilterLoaders";
import {
  getBasicQueryString,
  getBasicWithStaticOptionsRequestBody,
  getMultiSelectRequestBody,
  getDateRangeRequestBody,
  getShippabilityRequestBody,
  getNRequestBodyFilterValuePriority,
  getRequestBodyForVinStatus,
  getLocationRequestBody,
} from "../../../components/search-bar/open-search-filter-request-builder";
import { isDateRangeValueValid } from "components/search-bar/search-filter-validators";
import {
  AsyncSelectFilterButton,
  DateRangeFilterButton,
  BatchFilterButton,
  NFilterButton,
  SelectFilterButton,
} from "../../../components/search-bar/FilterButtons";
import { Features } from "../../../modules/auth/Authorization";
import {
  batchCsvVinExampleOpenSearch,
  batchCsvProductTypeExampleOpenSearch,
  batchCsvLast8ofVinExampleOpenSearch,
  batchCsvOrderNumberExampleOpenSearch,
} from "components/search-bar/batch-comment-csv-data";
import { translateExceptionName } from "pages/finishedvehicle/utils/exceptions.utils";
import { useEntityTranslation } from "shared/hooks/useEntityTranslation";
import { useLifecycleStateTranslation } from "shared/hooks/useEntityTranslation";

const VinStatusComponent = (props) => {
  const { getTranslatedStatus } = useLifecycleStateTranslation();

  let translatedOptions = props.options;

  for (const [status, subStatusOptions] of Object.entries(props?.options)) {
    translatedOptions[status] = subStatusOptions.map((option) => ({
      ...option,
      label: getTranslatedStatus(option.label),
    }));
  }

  return <NFilterButton {...props} options={translatedOptions} />;
};

VinStatusComponent.propTypes = {
  options: PropTypes.object,
};

const BatchComponent = (props) => {
  const { getTranslatedBatchSearchOptions } = useEntityTranslation();

  const translatedOptions = props?.options.map((option) => ({
    ...option,
    label: getTranslatedBatchSearchOptions(option.value),
  }));

  return <BatchFilterButton {...props} options={translatedOptions} />;
};

BatchComponent.propTypes = {
  options: PropTypes.array,
};

const CurrentLocationComponent = (props) => {
  const { getTranslatedCurrentLocationTypes } = useEntityTranslation();

  const translatedOptions = {
    currentPositionTypes: props?.options?.currentPositionTypes.map(
      (option) => ({
        ...option,
        label: getTranslatedCurrentLocationTypes(option.label),
      }),
    ),
  };

  return (
    <NFilterButton
      {...props}
      options={
        !_.isEmpty(translatedOptions.currentPositionTypes)
          ? translatedOptions
          : props.options
      }
    />
  );
};

CurrentLocationComponent.propTypes = {
  options: PropTypes.object,
};

export const OPEN_SEARCH_FILTERS = [
  {
    queryKey: "origin",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Origin"),
    optionsState: originOptionsState,
    transformFilterValue: (queryParameter, filterValue) =>
      getLocationRequestBody(queryParameter, filterValue, {
        valueKey: "code",
      }),
  },
  {
    queryKey: "destination",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Destination"),
    optionsState: destinationOptionsState,
    transformFilterValue: (queryParameter, filterValue) =>
      getLocationRequestBody(queryParameter, filterValue, {
        valueKey: "code",
      }),
  },
  {
    queryKey: "carrier",
    label: (t) => t("fv-vin-search:Carrier"),
    optionsGetter: (props) => props.carrierFilterOptionsOS,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "productTypeExact",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Product Type"),
    optionsState: productTypeOptionsStateOpenSearch,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: ["lifeCycleState", "activeSubStatus"],
    label: (t) => t("fv-vin-search:VIN Status"),
    Component: VinStatusComponent,
    nIsAsync: {
      lifeCycleState: false,
      activeSubStatus: false,
    },
    nLabels: {
      lifeCycleState: null,
      activeSubStatus: "Active Subcategory",
    },
    nRequirments: {
      activeSubStatus: ["Active"],
    },
    nIsMulti: {
      lifeCycleState: true,
      activeSubStatus: false,
    },
    nHideFuzzySearch: {
      lifeCycleState: true,
      activeSubStatus: true,
    },
    nHideSelectAll: {
      lifeCycleState: true,
      activeSubStatus: true,
    },
    nHideSelectEmpty: {
      lifeCycleState: true,
      activeSubStatus: true,
    },
    nDefaultValue: {
      activeSubStatus: "all_active",
    },
    showAll: false,
    optionsGetter: (props) => {
      return {
        lifeCycleState: props.lifeCycleStateFilterOptions,
        activeSubStatus: [
          { label: "All Active", value: "all_active" },
          { label: "Order Only", value: "prebuilt" },
          { label: "VIN Only", value: "active" },
        ],
      };
    },
    transformFilterValue: (queryParameter, filterValues) =>
      getRequestBodyForVinStatus(queryParameter, filterValues, "vinStatus"),
  },
  {
    queryKey: "vinExceptions",
    label: (t) => t("fv-vin-search:VIN Exception"),
    optionsGetter: (props, t) => {
      // H2-708: content of exceptions should be translated when presented.
      return props.exceptionTypeFilterOptions.map((item) => {
        return { ...item, label: translateExceptionName(item.label, t) };
      });
    },
    transformFilterValue: getBasicWithStaticOptionsRequestBody,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "lastMilestone",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Last Milestone"),
    optionsState: lastMilestoneOptionsStateOpenSearch,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "pickupDate",
    label: (t) => t("fv-vin-search:Pickup Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    transformFilterValue: getDateRangeRequestBody,
    dateTypeOptions: (t, auth) => {
      const options = [{ label: t("fv-vin-search:Actual"), value: "Actual" }];

      if (auth.hasFeatures([Features.FINISHED_VEHICLE_SCHEDULED_WINDOW])) {
        options.unshift({
          label: t("fv-vin-search:Scheduled"),
          value: "Scheduled",
        });
      }
      return options;
    },
    isValueValid: isDateRangeValueValid,
  },
  {
    queryKey: "deliveryDate",
    label: (t) => t("fv-vin-search:Delivery Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    transformFilterValue: getDateRangeRequestBody,
    dateTypeOptions: (t, auth) => {
      const options = [
        { label: t("fv-vin-search:Actual"), value: "Actual" },
        { label: t("fv-vin-search:ETA"), value: "ETA" },
      ];

      if (auth.hasFeatures([Features.FINISHED_VEHICLE_SCHEDULED_WINDOW])) {
        options.unshift({
          label: t("fv-vin-search:Scheduled"),
          value: "Scheduled",
        });
      }
      return options;
    },
    isValueValid: isDateRangeValueValid,
  },
  {
    queryKey: "orderType",
    label: (t) => t("fv-vin-search:Order Type"),
    optionsGetter: (props) => props.orderTypeFilterOptionsOS,
    transformFilterValue: getBasicWithStaticOptionsRequestBody,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "soldTo",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Sold To"),
    isMulti: true,
    optionsState: soldToOptionsStateOpenSearch,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
    requiredOrgFilters: "FIN_SOLD_TO",
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "finCode",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:FIN Code"),
    isMulti: true,
    optionsState: finCodeOptionsState,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
    requiredOrgFilters: "FIN_FINCODE",
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "endUserFinCode",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:End User FIN Code"),
    isMulti: true,
    optionsState: endUserFinCodeOptionsStateOpenSearch,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
    requiredOrgFilters: "FIN_ENDUSER_FINCODE",
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "partner",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Partner"),
    optionsState: partnerOptionsStateOpenSearch,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "shipmentStatus",
    label: (t) => t("fv-vin-search:Shipment Status"),
    optionsGetter: (props) => props.shipmentStatusFilterOptionsOS,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "itssId",
    label: (t) => t("fv-vin-search:ITSS ID"),
    optionsGetter: (props) => props.itssFilterOptionsOS,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "spotBuyAuth",
    label: (t) => t("fv-vin-search:Spot Buy Auth"),
    optionsGetter: (props) => props.spotBuyFilterOptionsOS,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "originCountry",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Origin Country"),
    isMulti: true,
    hideFuzzySearch: true,
    optionsState: originCountryOptionsStateOpenSearch,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "originRegion",
    label: (t) => t("shipment-search:Origin Region"),
    optionsGetter: (props) => props.regionFilterOptions,
    transformFilterValue: getBasicWithStaticOptionsRequestBody,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "destinationCountry",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Destination Country"),
    isMulti: true,
    hideFuzzySearch: true,
    optionsState: destinationCountryOptionsStateOpenSearch,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "destinationRegion",
    label: (t) => t("shipment-search:Destination Region"),
    optionsGetter: (props) => props.regionFilterOptions,
    transformFilterValue: getBasicWithStaticOptionsRequestBody,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: "shippability",
    Component: SelectFilterButton,
    label: (t) => t("fv-vin-search:Shippability"),
    optionsGetter: (props, t) => {
      return [
        {
          label: t("fv-vin-search:Shippable"),
          value: "shippable",
        },
        {
          label: t("fv-vin-search:Non-Shippable"),
          value: "non-shippable",
        },
      ];
    },
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: true,
    transformFilterValue: getShippabilityRequestBody,
    requiredOrgFilters: "FIN_SHIPPABILITY",
  },
  {
    queryKey: "dealerRegion",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Dealer Region"),
    isMulti: true,
    optionsState: dealerRegionOptionsStateOpenSearch,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
    requiredOrgFilters: "FIN_DEALER_REGION",
  },
  {
    queryKey: "dealerZone",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Dealer Zone"),
    isMulti: true,
    optionsState: dealerZoneOptionsStateOpenSearch,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
    requiredOrgFilters: "FIN_DEALER_ZONE",
  },
  {
    queryKey: "dealerDistrict",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Dealer District"),
    isMulti: true,
    optionsState: dealerDistrictOptionsStateOpenSearch,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
    requiredOrgFilters: "FIN_DEALER_DISTRICT",
  },
  {
    queryKey: "batch",
    label: (t) => t("fv-vin-search:Batch Search"),
    Component: BatchComponent,
    /**
     * The filters with the "Batch" suffix
     * (e.g., "vinNumberBatch", "productTypeBatch") are used to handle batch search functionality.
     * It is used to differentiate between the category search and batch search filters.
     * last8OfVin is not changed here as it is not one of the category search filters.
     */
    optionsGetter: () => {
      return [
        {
          value: "vinNumberBatch",
          batchCsvExample: batchCsvVinExampleOpenSearch,
        },
        {
          value: "productTypeBatch",
          batchCsvExample: batchCsvProductTypeExampleOpenSearch,
        },
        {
          value: "last8OfVin",
          batchCsvExample: batchCsvLast8ofVinExampleOpenSearch,
        },
        {
          value: "orderNumberBatch",
          batchCsvExample: batchCsvOrderNumberExampleOpenSearch,
        },
      ];
    },
    transformFilterValue: (queryParameter, filterValue) => {
      if (filterValue) {
        const batchValues = filterValue.batch_list.split(",");
        return {
          [filterValue.batch_type]: { values: batchValues },
        };
      }
    },
  },
  // Hidden parameter for saved searches.
  // This query param lets us change the logic for the exception field to be an AND instead of an OR.
  // e.g. Search for VINs that have Fuel Level and Tire Pressure; entitySearchAnd=exception&exception=id1,id2
  // (usually this would be Fuel Level or Tire Pressure)
  {
    queryKey: "entitySearchAnd",
    queryBuilder: getBasicQueryString,
    hidden: () => true,
  },
  {
    // ordered top down from 0 - N
    queryKey: ["currentPositionTypes", "currentPositionCodes"],
    label: (t) => t("fv-vin-search:Current Location"),
    Component: CurrentLocationComponent,
    nIsAsync: {
      currentPositionTypes: false,
      currentPositionCodes: true,
    },
    nOptionsState: {
      currentPositionTypes: null,
      currentPositionCodes: currentPositionsCodeOptionsState,
    },
    //Label of filter where key is corresponding select
    nLabels: {
      currentPositionTypes: null,
      currentPositionCodes: "At Location",
    },
    //Fields required from previous filter
    //Field with contraints as key
    nRequirments: {
      currentPositionCodes: ["AtLocation"],
    },
    // Hides fuzzy search options
    nHideFuzzySearch: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select all option
    nHideSelectAll: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    // Hides select empty option
    nHideSelectEmpty: {
      currentPositionTypes: true,
      currentPositionCodes: false,
    },
    //Showall filters with no requirments or constraints
    showAll: false,
    //Object key as query key
    optionsGetter: (props) => {
      return {
        currentPositionTypes: props.currentPositionTypes ?? [],
      };
    },
    transformFilterValue: (queryParameter, filterValues) =>
      getNRequestBodyFilterValuePriority(queryParameter, filterValues),
  },
  {
    queryKey: "completedDate",
    label: (t) => t("fv-vin-search:Completed Date"),
    Component: DateRangeFilterButton,
    transformFilterValue: getDateRangeRequestBody,
    isValueValid: isDateRangeValueValid,
    requiredOrgFilters: "FIN_COMPLETED_DATE",
  },
  {
    queryKey: "routeId",
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("fv-vin-search:Route ID"),
    optionsState: routeIdOptionsStateOpenSearch,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "exteriorColor",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Exterior Color"),
    isMulti: true,
    optionsState: exteriorColorOptionsState,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "interiorColor",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Interior Color"),
    isMulti: true,
    optionsState: interiorColorOptionsState,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "doorType",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Doors"),
    isMulti: true,
    optionsState: doorsOptionsState,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  {
    queryKey: "powerTrain",
    Component: AsyncSelectFilterButton,
    label: (t) => t("fv-vin-search:Power Train"),
    isMulti: true,
    optionsState: powertrainOptionsState,
    transformFilterValue: (queryParameter, filterValue) =>
      getMultiSelectRequestBody(queryParameter, filterValue),
  },
  // TODO: Shiva: Add these new fitlers to the OPEN_SEARCH_FILTERS array once confirmed
  // {
  //   queryKey: "driveTrain",
  //   Component: AsyncSelectFilterButton,
  //   label: (t) => t("fv-vin-search:Drive Train"),
  //   isMulti: true,
  //   optionsState: drivetrainOptionsState,
  //   transformFilterValue: (queryParameter, filterValue) =>
  //     getMultiSelectRequestBody(queryParameter, filterValue),
  // },
  // {
  //   queryKey: "bodyStyle",
  //   Component: AsyncSelectFilterButton,
  //   label: (t) => t("fv-vin-search:Body Style"),
  //   isMulti: true,
  //   optionsState: bodyStyleOptionsState,
  //   transformFilterValue: (queryParameter, filterValue) =>
  //     getMultiSelectRequestBody(queryParameter, filterValue),
  // },
  // {
  //   queryKey: "fuelType",
  //   Component: AsyncSelectFilterButton,
  //   label: (t) => t("fv-vin-search:Fuel Type"),
  //   isMulti: true,
  //   optionsState: fuelTypeOptionsState,
  //   transformFilterValue: (queryParameter, filterValue) =>
  //     getMultiSelectRequestBody(queryParameter, filterValue),
  // },
  // {
  //   queryKey: "year",
  //   Component: AsyncSelectFilterButton,
  //   label: (t) => t("fv-vin-search:Year"),
  //   isMulti: true,
  //   optionsState: yearOptionsState,
  //   transformFilterValue: (queryParameter, filterValue) =>
  //     getMultiSelectRequestBody(queryParameter, filterValue),
  // },
  // {
  //   queryKey: "make",
  //   Component: AsyncSelectFilterButton,
  //   label: (t) => t("fv-vin-search:Make"),
  //   isMulti: true,
  //   optionsState: makeOptionsState,
  //   transformFilterValue: (queryParameter, filterValue) =>
  //     getMultiSelectRequestBody(queryParameter, filterValue),
  // },
  // {
  //   queryKey: "model",
  //   Component: AsyncSelectFilterButton,
  //   label: (t) => t("fv-vin-search:Model"),
  //   isMulti: true,
  //   optionsState: modelOptionsState,
  //   transformFilterValue: (queryParameter, filterValue) =>
  //     getMultiSelectRequestBody(queryParameter, filterValue),
  // },
  // {
  //   queryKey: "trim",
  //   Component: AsyncSelectFilterButton,
  //   label: (t) => t("fv-vin-search:Trim"),
  //   isMulti: true,
  //   optionsState: trimOptionsState,
  //   transformFilterValue: (queryParameter, filterValue) =>
  //     getMultiSelectRequestBody(queryParameter, filterValue),
  // },
];
